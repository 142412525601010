import React from "react"

import Container from "components/container"
import { DriveLayout } from "components/layout-custom"
import { NewIssueStandalonePage } from "components/maintenance/issues/new"

const Page = () => {
  const title = "Report A Defect"
  return (
    <DriveLayout title={title}>
      <Container>
        <NewIssueStandalonePage title={title} />
      </Container>
    </DriveLayout>
  )
}

export default Page
